import { graphql, Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { SEO } from "~/components/SEO";
import { useStringsContext } from "~/contexts/strings-context";
import { PageProps } from "~/types/global";
import AboutSVG from "~/assets/svg/about";
import { Title } from "~/components/text/typography";
import Mission from "~/assets/svg/mission";
import Ethos from "~/assets/svg/ethos";
import Pedagogy from "~/assets/svg/pedagogy";
import PrinciplDeskSVG from "~/assets/svg/principalDesk";

export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            varqaschool
          }
    }
`;

const Container = styled.div`
  display: flex;
  flex-flow: row-reverse nowrap;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100%;
  @media(max-width: 1300px) {
      flex-direction: column-reverse;
      margin-bottom: 3rem;
      height: auto;
    }
`;

const ContainerPrincipal = styled(Container)`
  height: 100%;
  margin: 5rem 0;
`;

export const Card = styled(Link)`
  width: 20rem;
  height: 23rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  background: rgba(236, 236, 236, 0.219);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.6px);
  -webkit-backdrop-filter: blur(10.6px);
  border-radius: 20px;
  text-decoration: none;
  :hover {
    box-shadow:-4px 8px 8px rgba(0,0,0,0.1), -8px 16px 16px rgba(0,0,0,0.1), -16px 32px 32px rgba(0,0,0,0.15), -32px 64px 64px rgba(0,0,0,0.25);
    ::after{
      content: "Click here to know more";
      color: #ffffff;
      text-align: center;
      font-family: "Play";
      font-size: 1rem;
    }
    cursor: pointer;
  }
`;

const TitleAbout = styled.h1`
    display: flex;
    line-height: 2.7rem;
    text-align: start;
    font-size: 1.8rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #f8f8f8;
    align-items: center;
    flex-wrap: nowrap;
    min-width: 100%;
    margin: 0 1rem 1rem;
`;

const ContainerText = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  max-width: 50vw;
  @media(max-width: 1300px) {
    max-width: 100vw;
    margin: 5rem 0 0 0;
    }
`;

const Text = styled.h1`
  font-size: 1.3rem;
  color: whitesmoke;
  font-family: "Cinzel";
  text-align: start;
  margin: 0 1rem 2rem;
  line-height: 2rem;
  letter-spacing: 1px;
  padding-top: 1rem;
`;
const CardText = styled(Text)`
  text-align: center;
`;
const TextPrincipal = styled(Text)`
  margin: 0 0 0 1rem;
`;
const AboutPage: React.FunctionComponent<PageProps> = (props) => {
  const strings = useStringsContext();
  return (
    <>
      <SEO
        lang={props.pageContext.language}
        title="Varqa School - About"
        description="School Trust - is an initiative of Varqa Charitable Institution located in Ahmednagar. It is guided by the principles of Bahá’í world faith, which is known internationally for its endeavors towards world peace &amp; universal education."
        keywords={["varqa", "school", "varqa school", "varka", "vaka", "varkha", "varqa school", "college", "ahmenagar", "ahmednagar", "maharashtra", "best school", "schools near me", "schol", "schoole", "schools", "varqa", "best", "top schools", "cbse", "cbse school", "state board", "state", "english medium", "school medium", "english school", "english", "ramin", "ruhiya", "saba", "sir", "madam", "ahmednagr best school", "nagar best school", "nagar", "school in nagar", "nagar school"]}
        url={props.location.pathname}
      />
      <Container>
        <AboutSVG/>
        <ContainerText>
          <TitleAbout>About Varqa Charitable Institution</TitleAbout>
          <Text>School Trust - is an initiative of Varqa Charitable Institution located in Ahmednagar. It is guided by the principles of Bahá’í world faith, which is known internationally for its endeavors towards world peace &amp; universal education.</Text>
        </ContainerText>
        
      </Container>
      <Container>
        <Card to="/about/ethos"><CardText>Our Ethos</CardText><Ethos/></Card>
        <Card to="/about/pedagogy"><CardText>Our Pedagogy</CardText><Pedagogy/></Card>
        <Card to="/about/mission"><CardText>OUR MISSION</CardText><Mission/></Card>
      </Container>
      <ContainerPrincipal>
        <ContainerText>
          <TitleAbout>From Principal's Desk</TitleAbout>
          <TextPrincipal>We welcome your child / ward to Varqa School for the new academic session and hope that it will prove to be year of achieving new heights. The school endeavors to help the students to grow happily in an atmosphere of love and unity and to acquire not only necessary academic skills, but also a spiritual qualities such as honesty, truthfulness , love, Justice, respect for elders and so on. So that they may become responsible and productive members of society.</TextPrincipal>
	        <TextPrincipal>Varqa school was established in the loving memory of the beloved Varqa , a Baha`’i` Martyr who scarified his life for the truth and welfare of humanity. Varqa is also a symbol of peace.</TextPrincipal>
	        <TextPrincipal>Varqa school is a  Baha`’i`  School and it’s activities and policies are in accordance with the Baha`’i` education.</TextPrincipal>
          <TextPrincipal>The school endeavors to help each student fully develop his / her personality in an atmosphere of love and unity. Attention is paid to striking a balance between spiritual, intellectual, social and physical education. Varqa school aspires to develop international community. The students and the well qualified faculty, come from a wide variety of religious, nationalities, races, castes and creeds with all seeking to find unity in the school moto.</TextPrincipal>
        </ContainerText>
        <PrinciplDeskSVG/>
      </ContainerPrincipal>
    </>
  )
}

export default AboutPage
